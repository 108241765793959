/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC, useMemo, useState } from 'react';

// Material-UI imports
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  LinearProgress,
  Typography,
  useMediaQuery /* useMediaQuery, useTheme */,
  useTheme,
} from '@material-ui/core';

// API imports
import { ErpItemDefinition, assemblyitemList } from '@wisi-tv/okapi-api';
import { useSelector } from 'react-redux';
import { useRequest } from 'redux-query-react';
import { AppState } from '../../../../store';
import { AssemblyItemTable } from './components/AssemblyItemTable';
import { ErpItemDefinitionDetails } from './types';
import { BomInputForm } from './components/BomInputForm';

// Common imports

// Local imports

export interface ProductionProps {
  hide?: boolean;
}

/**
 * Main tool used for displaying the product resources.
 */
export const Production: FC<ProductionProps> = (props: ProductionProps) => {
  const PRODUCTION_TOOL_HOME = 'home';
  const PRODUCTION_TOOL_BUILD_ASSEMBLY = 'buildassembly';

  /** **************************************************************************
   *  Initial theme and hook setup
   ************************************************************************** */
  const theme = useTheme();
  const mdMatches = useMediaQuery(theme.breakpoints.up('md'));

  /** **************************************************************************
   * Redux query selectors and dispatchers
   ************************************************************************** */

  const assemblyItems = useSelector<AppState, ErpItemDefinition[]>((state: AppState) => state.entities.assemblyitemList);

  const loadingState = useRequest(assemblyitemList({}))[0];

  /** **************************************************************************
   * Local state
   ************************************************************************** */

  const [productionTool, setProductionTool] = useState(PRODUCTION_TOOL_HOME);
  const [bomFormOpen, setBomFormOpen] = useState(false);
  const [selectedErpItem, setSelectedErpItem] = useState<ErpItemDefinition | undefined>();

  /** **************************************************************************
   * Event handlers
   ************************************************************************** */

  /** **************************************************************************
   * Local variables - typically derived from state and used in renders
   ************************************************************************** */

  /** **************************************************************************
   * Render functions
   ************************************************************************** */

  const renderHome = useMemo(() => {
    return (
      <Box>
        <Typography variant="body1">
          This page offers various tools for the operations specialist. Choose from a tool below to continue.
        </Typography>
        <Box height={theme.spacing(2)} />
        <Typography variant="h2">Build Assembly</Typography>
        <Box height={theme.spacing(2)} />
        <Typography variant="body1">
          This tool allows you to build an assembly item from NetSuite. You will first select an assembly item by its Id
          and then fill out all the serial numbers required in the BOM.
        </Typography>
        <Box height={theme.spacing(1)} />
        <Button color="default" variant="contained" onClick={() => setProductionTool(PRODUCTION_TOOL_BUILD_ASSEMBLY)}>
          Build Assembly
        </Button>
      </Box>
    );
  }, [theme]);

  const renderBuildAssembly = useMemo(() => {
    if (loadingState.isPending) {
      return (
        <>
          <Typography variant="h3">Loading available items to build...</Typography>
          <Box height={theme.spacing(3)} />
          <LinearProgress/>
        </>
      )
    }
    return (
      <Box>
        <AssemblyItemTable
          onBuildErpItem={(event, selectedErpItems) => {
            setSelectedErpItem(selectedErpItems[0]);
            setBomFormOpen(true);
          }}
          pageSize={25}
          paging={assemblyItems && assemblyItems.length > 25}
          items={assemblyItems as ErpItemDefinitionDetails[]}
          isDesktop={mdMatches}
        />
        <BomInputForm isOpen={bomFormOpen} setIsOpen={setBomFormOpen} selectedErpItem={selectedErpItem} />
      </Box>
    );
  }, [assemblyItems, mdMatches, bomFormOpen, setBomFormOpen, selectedErpItem, loadingState.isPending, theme]);

  /**
   * Render the main tool - this really only should get re-rendered if
   * the adminAccess level changes, the list of devices changes, or if
   * the media query determining the screen size changes.
   */
  const renderTool = useMemo(() => {
    return (
      <Container maxWidth="lg">
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <Typography color="primary" variant="h1">
              /
            </Typography>
          }
        >
          <Button
            style={{ paddingLeft: 0, paddingRight: 0 }}
            variant="text"
            onClick={() => setProductionTool(PRODUCTION_TOOL_HOME)}
          >
            <Typography
              style={{ textDecoration: `${productionTool !== PRODUCTION_TOOL_HOME ? 'underline' : 'none'}` }}
              color="primary"
              variant="h1"
            >
              Production
            </Typography>
          </Button>
          {productionTool === PRODUCTION_TOOL_BUILD_ASSEMBLY && (
            <Typography color="primary" variant="h1">
              Build Assembly
            </Typography>
          )}
        </Breadcrumbs>
        {productionTool === PRODUCTION_TOOL_HOME && renderHome}
        {productionTool === PRODUCTION_TOOL_BUILD_ASSEMBLY && renderBuildAssembly}
      </Container>
    );
  }, [productionTool, renderHome, renderBuildAssembly]);

  /** **************************************************************************
   * Main Render
   ************************************************************************** */

  if (props.hide) {
    return <></>;
  }

  return <>{renderTool}</>;
};
