/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC } from 'react';

// Third party imports
import { BuildOutlined } from '@material-ui/icons';
import { Column } from 'material-table';

// Common imports
import { MaterialTable } from '../../../../../components';
import { ErpItemDefinitionDetails } from '../types';


// Local imports


export interface AssemblyItemTableProps {
  /**
   * Optional styling class.
   */
  className?: string;

  /**
   * Content for component.
   */
  items: ErpItemDefinitionDetails[];

  /**
   * Is this component being rendered on a desktop?
   */
  isDesktop?: boolean;

  /**
   * Is paging enabled or not for this table.
   */
  paging?: boolean;

  /**
   * Page size if paging is enabled.
   */
  pageSize?: number;

  /**
   * Called when the button to build an item is clicked.
   */
  onBuildErpItem: (event: React.MouseEvent<HTMLButtonElement>, selectedRows: ErpItemDefinitionDetails[]) => void;
}

/**
 * Table used to present all of the team members.
 */
export const AssemblyItemTable: FC<AssemblyItemTableProps> = (props: AssemblyItemTableProps) => {
  const columns: Column<ErpItemDefinitionDetails>[] = [
    { title: 'Name', field: 'displayName' },
    { title: 'Description', field: 'description' },
    { title: 'Internal Id', field: 'internalId' }
  ];

  const actions = [
        // Invite member is a "free action" meaning it is always shown
        {
          icon: () => <BuildOutlined color="primary" />,
          tooltip: 'Build Item',
          onClick: (event: React.MouseEvent<HTMLButtonElement>, selection: ErpItemDefinitionDetails | ErpItemDefinitionDetails[]) => {
            props.onBuildErpItem(event, Array.isArray(selection) ? selection : [selection]);
          }
        }
      ];

  return (
    <div className={props.className}>
      <MaterialTable
        options={{
          maxBodyHeight: 'calc(100vh - 250px)',
          actionsColumnIndex: -1,
          selection: false,
          searchFieldVariant: 'outlined',
          showTextRowsSelected: false,
          searchFieldStyle: { width: props.isDesktop ? '500px' : '200px' },
          paging: props.paging,
          pageSize: props.pageSize,
          pageSizeOptions: [props.pageSize || 0],
        }}
        localization={{
          toolbar: {
            searchPlaceholder: 'Search name, description, id, ...',
          },
        }}
        searchFieldAlignment="left"
        columns={columns}
        data={props.items}
        actions={actions}
      />
    </div>
  );
};

AssemblyItemTable.defaultProps = {
  pageSize: 100,
} as Partial<AssemblyItemTableProps>;
