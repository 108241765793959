/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import { ThemeProvider, Backdrop, CircularProgress } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';

import { usersMeRead } from '@wisi-tv/okapi-api';
import { useRequest } from 'redux-query-react';
import * as queryString  from 'query-string';
import { theme } from './theme';
import { LoginPage, HomePage } from './pages';
import { AppState } from './store';
import { ErrorBoundary } from './components';
import { setIsLoggedIn } from './store/slices/system';

export const App: React.FC = () => {
  const isLoggedIn = useSelector((state: AppState) => state.system.isLoggedIn);
  const dispatch = useDispatch();
  const forceUserRead = useRequest(usersMeRead({ force: true }))[1];

  const {location} = window;

  const parsed = queryString.parse(location.search);

  if (typeof(parsed.csrftoken) === 'string') {
    localStorage.setItem('csrftoken', parsed.csrftoken);
  } else if (parsed.csrftoken) {
    localStorage.setItem('csrftoken', parsed.csrftoken[0]);
  }

  useEffect(() => {
    forceUserRead().then((response) => {
      let refreshSuccess = false;
      if (response.status === 200) {
        refreshSuccess = true;
      }
      if (isLoggedIn !== refreshSuccess) {
        dispatch(setIsLoggedIn(refreshSuccess));
      }
      return null;
    }).catch(() => {
      dispatch(setIsLoggedIn(false));
    });
  }, [isLoggedIn, dispatch, forceUserRead]);

  if (isLoggedIn === undefined) {
    return (
      <ThemeProvider theme={theme}>
        <IonApp>
          <Backdrop open>
            <CircularProgress />
          </Backdrop>
        </IonApp>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet>
              <Route
                path="/login"
                exact
                render={() => {
                  if (isLoggedIn) {
                    return <Redirect from="/login" to="/home" />;
                  }
                  return <LoginPage />;
                }}
              />
              <Route
                path="/home"
                render={() => {
                  if (isLoggedIn) {
                    return <HomePage />;
                  }
                  return <Redirect from="/home" to="/login" />;
                }}
              />
              <Redirect path="/" exact from="/" to="/login" />
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
