/*
 *
 * Copyright 2020 WISI America.   All rights reserved.
 *
 */

/* inserted by copyright_tool */

// Framework imports
import React, { FC, useMemo, useState } from 'react';
import { IonPage } from '@ionic/react';
import { useSelector } from 'react-redux';

// Material-UI imports
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { BuildOutlined } from '@material-ui/icons';

// API imports
import { UserDetails } from '@wisi-tv/okapi-api';

// Common imports
import { Content, HeaderBar } from '../../components';
import { Tool } from '../../components/headerbar';
import { Server, Group, Manual } from '../../components/icons';

// Local imports
import { Team, Devices, Products, Production } from './tools';
import { AppState } from '../../store';
import { hasAccess } from '../../utils';

/**
 * This is used to render the main home page presented after login.
 */
export const HomePage: FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [currentTool, setCurrentTool] = useState('devices');
  const [drawerWidth, setDrawerWidth] = useState('0');

  const userRead = useSelector<AppState, UserDetails>((state: AppState) => state.entities.usersMeRead);
  const selectedOrg = useSelector((state: AppState) => state.system.selectedOrg) || null;

  const { groups } = userRead || {};

  const tools: Tool[] = [
    {
      slug: 'devices',
      name: 'Devices',
      icon: Server,
    },
    {
      slug: 'team',
      name: 'Team',
      icon: Group,
    },
    {
      slug: 'products',
      name: 'Resources',
      icon: Manual,
    },
  ];

  const productionAccess = useMemo(() => hasAccess(groups as Record<string, unknown>, selectedOrg, ['sys:production']), [groups, selectedOrg]);

  if (productionAccess) {
    tools.push(
      {
        slug: 'production',
        name: 'Production',
        icon: BuildOutlined,
      }
    )
  }

  return (
    <IonPage>
      <Content>
        <HeaderBar
          link="https://wisi.tv"
          tools={tools}
          onDrawerWidthChange={setDrawerWidth}
          onToolChange={setCurrentTool}
        />
        <Box
          height="100%"
          overflow="auto"
          marginTop={`${matches ? theme.spacing(7) : theme.spacing(9)}px`}
          marginLeft={drawerWidth}
        >
          {currentTool === 'team' && <Team />}
          <Devices hide={currentTool !== 'devices'} />
          <Products hide={currentTool !== 'products'} />
          {productionAccess && currentTool === 'production' && <Production />}
        </Box>
      </Content>
    </IonPage>
  );
};
